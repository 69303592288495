import {Navigate} from "react-router-dom"
import {ReactElement} from "react";
import {SUPABASE} from "@/lib/db";
import {useSession} from "@/hooks/useSession";

export const RequireToken = ({children}: { children: ReactElement }) => {
  const { session, loading } = useSession();

  if (loading) {
    return <></>
  } else if (!session) {
    return <Navigate to='/login' replace/>;
  } else {
    return children;
  }
}

export const Logout = () => {
  SUPABASE.auth.signOut().then(() => {})

  return <Navigate to='/login' replace />;
}


export const signInWithGoogle = async () => {
  await SUPABASE.auth.signInWithOAuth({
    provider: 'google',
    options: {
      queryParams: {
        access_type: 'offline',
        prompt: 'consent',
      },
    },
  });
};


export const signInWithMicrosoft = async () => {
  await SUPABASE.auth.signInWithOAuth({
    provider: 'azure',
    options: {
      scopes: 'openid profile email offline_access',
    },
  });
};