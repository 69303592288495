import React, {useEffect, useState} from "react";
import {endpoints} from "@/api/endpoints";
import {Button} from "@/components/ui/Button";
import {Link, useNavigate} from "react-router-dom";
import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/Card";
import {Label} from "@/components/ui/Label";
import { Input } from "@/components/ui/Input";
import {Badge} from "@/components/ui/Badge";
import {Alert, AlertDescription, AlertTitle} from "@/components/ui/Alert";
import {CheckIcon} from "@heroicons/react/24/solid";
import {useLocation} from "react-router-dom";
import {RemainingCredits} from "@/components/RemainingCredits";
import {Skeleton} from "@/components/ui/Skeleton";
import {authenticatedRequestBody} from "@/api/services/auth";
import {getUserData} from "@/api/services/user";

const SectionHeader = ({children}: {children: React.ReactNode}) => {
  return (
    <h3 className={"text-medium text-gray-500 font-medium"}>
      {children}
    </h3>
  );
}

const SkeletonRows = () => {
  return (
    <div className={"mt-2 flex flex-col gap-2"}>
      <Label>Email</Label>
      <Skeleton className={"h-[2rem] w-full"}/>

      <Label>Name</Label>
      <Skeleton className={"h-[2rem] w-full"}/>

      <Label>Industry</Label>
      <Skeleton className={"h-[2rem] w-full"}/>

      <Label>Company</Label>
      <Skeleton className={"h-[2rem] w-full"}/>

      <Label>Position / Role</Label>
      <Skeleton className={"h-[2rem] w-full"}/>

      <Skeleton className={"h-[2.5rem] w-full mt-2"}/>
    </div>
  );
}

const SkeletonSubscription = () => {
  return (
    <div className={"flex flex-col gap-2"}>
      <SectionHeader>Subscription</SectionHeader>
      <div className={"flex items-center gap-2"}>
        <span>Status:</span>
        <Skeleton className={"h-[2ch] w-[8ch]"}/>
      </div>

      <Skeleton className={"h-[2.5rem] w-full"}/>
    </div>
  );

}

/**
 * Account page.
 *
 * Contains user information and subscription status, with the ability to update user details as well as upgrade to a paid subscription.
 *
 * If the user has just upgraded their account (denoted by the query parameter `upgraded=true`), a success message will be displayed.
 * @constructor
 */
export const Account = () => {
  const [loading, setLoading] = useState(true);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [industry, setIndustry] = useState("");
  const [paid, setPaid] = useState(false);

  const [statusMessage, setStatusMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  // this is used to get the query parameters from the URL
  // for example, if the user has just upgraded their account
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => setCompany(e.target.value);
  const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => setPosition(e.target.value);
  const handleIndustryChange = (e: React.ChangeEvent<HTMLInputElement>) => setIndustry(e.target.value);

  useEffect(() => {
    // Check if the user has just upgraded their account
    const upgraded = queryParams.get('upgraded');
    if (upgraded === 'true') {
      setStatusMessage("You have successfully upgraded your account!");
    }

    // Fetch user details
    getUserData().then(data => {
      if (data) {
        setEmail(data.email);
        setName(data.name);
        setCompany(data.company);
        setIndustry(data.industry || "");
        setPosition(data.position || "");
        setPaid(data.paid_status);

        setLoading(false);
      }
    }).catch(error => {
      console.error(error);
      navigate('/login/first');
    })
  }, [navigate, queryParams]);

  const updateUserDetails = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const session_data = await authenticatedRequestBody({
      name: name,
      company: company,
      industry: industry,
      position: position,
    });
    fetch(endpoints.update_metadata, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: session_data,
    }).then(async (response) => {
      if (response.status !== 200) {
        console.error(response);
        return;
      }
      return await response.json();
    }).then((data) => {
      if (data.error) {
        console.error(data.error);
        return;
      } else if (data.success) {
        setStatusMessage("User details updated successfully!");
      }
    })
  }

  return (
    <div className={"flex items-center justify-center mt-[10vh] mb-[10vh]"}>
      <Card className={"w-[95vw] sm:w-[65vw] md:w-[50vw] lg:w-[40vw]"}>
        <CardHeader>
          <CardTitle>
            Account Details
          </CardTitle>
        </CardHeader>
        <CardContent className={"flex flex-col gap-4"}>
          {statusMessage &&
              <Alert className={"text-green-500 border-green-500/70 mb-4"}>
                  <CheckIcon className="h-4 w-4 text-green-500"/>
                  <AlertTitle>Success!</AlertTitle>
                  <AlertDescription>{statusMessage}</AlertDescription>
              </Alert>
          }
          <div>
            <SectionHeader>User Information</SectionHeader>
            {!loading &&
              <form onSubmit={updateUserDetails}>
                <div className={"flex flex-col gap-2"}>
                  <div>
                    <Label>
                      Email
                    </Label>
                    <Input
                      type="text"
                      className={"min-w-full"}
                      value={email}
                      disabled
                    />
                  </div>
                  <div>
                    <Label>
                      Name
                    </Label>
                    <Input
                      type="text"
                      className={"min-w-full"}
                      placeholder={"First Last"}
                      value={name}
                      onChange={handleNameChange}
                      required
                    />
                  </div>
                  <div>
                    <Label>
                      Industry
                    </Label>
                    <Input
                      type="text"
                      className={"min-w-full"}
                      placeholder={"Your Industry (ie: Signage, Construction, etc)"}
                      value={industry}
                      onChange={handleIndustryChange}
                      required
                    />
                  </div>
                  <div>
                    <Label>
                      Company
                    </Label>
                    <Input
                      type="text"
                      className={"min-w-full"}
                      placeholder={"Your Company Name"}
                      value={company}
                      onChange={handleCompanyChange}
                    />
                  </div>
                  <div>
                    <Label>
                      Position / Role
                    </Label>
                    <Input
                      type="text"
                      className={"min-w-full"}
                      placeholder={"Your position / role in your company"}
                      value={position}
                      onChange={handlePositionChange}
                    />
                  </div>
                  <Button type="submit" className={"mt-2"}>Update</Button>
                </div>
              </form>
            }

            {loading && <SkeletonRows />}

          </div>
          {!loading && (
            <div className={"flex flex-col gap-2"}>

              <SectionHeader>Subscription</SectionHeader>

              <div className={"flex items-center gap-2"}>
                <span>Status:</span>
                {paid ?
                  <Badge className={"bg-green-500"}>Subscribed!</Badge> :
                  <Badge className={"bg-red-500"}>Not Subscribed</Badge>}
              </div>

              <RemainingCredits />

              {paid ? (
                <Link to={"/customer_portal"}>
                  <Button className={"min-w-full"} variant={"secondary"}>
                    Manage Billing Details
                  </Button>
                </Link>
              ) : (
                <Link to={"/subscribe"}>
                  <Button className={"min-w-full bg-green-500 hover:bg-green-500/90"}>
                    Upgrade to Paid
                  </Button>
                </Link>
              )}
            </div>
          )}

          {loading && <SkeletonSubscription />}
        </CardContent>
      </Card>
    </div>
  );
}