import React, {ChangeEvent, useId} from 'react';
import { XMarkIcon } from "@heroicons/react/24/solid";
import {Button} from "@/components/ui/Button";
import {Input} from "@/components/ui/Input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from '@/components/ui/Select';
import {Preview} from "./Preview";
import {featureFlags} from "@/featureFlags";
import {Label} from "@/components/ui/Label";
import {tourElementIds} from "@/app/tour";
import "./fonts.css";
import {MAX_TEXT_HEIGHT, MAX_TEXT_LENGTH, MIN_TEXT_HEIGHT} from "@/api/constants";

const availableFonts = [
  "Arial",
  "Verdana",
  "Times New Roman",
  "Calibri",
  "Impact",
  "Franklin Gothic",
  "Garamond",
  "Comic Sans MS",
  "Courier New",
  "Rockwell",
  "Helvetica",
  "Futura",
  "Bodoni",
  "Frutiger",
  "Myriad Pro Regular",
  "Gotham",
];

interface TextRowProps {
  text: string;
  size: number;
  font: string;
  onTextChange: (text: string) => void;
  onSizeChange: (size: number) => void;
  onFontChange: (font: string) => void;
  removeRow?: () => void;
  first?: boolean;
}

const TextRow = ({ text, size, font, onTextChange, onSizeChange, onFontChange, removeRow, first }: TextRowProps) => {
  // Generate unique id's for inputs
  const textId = useId();
  const heightId = useId();
  const fontId = useId();

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    onTextChange(event.target.value);
  };

  const handleSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSizeChange(event.target.valueAsNumber);
  };

  const handleFontChange = (value: string) => {
    onFontChange(value);
  };

  return (
    <div className={"flex flex-row gap-3"}>
      <div className={"flex flex-col md:flex-row gap-3 flex-grow"}>
        <div className={"grow"}>
          <Label htmlFor={textId}>
            Text <span className={"text-gray-500"}>(Optional)</span>
            &nbsp;&nbsp;
            <span className={"text-xs text-gray-500"}>{text.length}/{MAX_TEXT_LENGTH}</span>
          </Label>
          <Input type="text" value={text}
                 id={textId}
                 placeholder="Text"
                 maxLength={MAX_TEXT_LENGTH}
                 onChange={handleTextChange}/>
        </div>

        <div className={"flex flex-row gap-3"}>
          <div>
            <Label htmlFor={heightId}>
              Height (in)
            </Label>
            <Input id={heightId} type={"number"} value={size}
                   className={"min-w-[10ch]"}
                   placeholder={"Height (in)"}
                   min={MIN_TEXT_HEIGHT} max={MAX_TEXT_HEIGHT}
                   onChange={handleSizeChange}/>
          </div>


          <div>
            <Label htmlFor={fontId}>
              Font
            </Label>
            <Select onValueChange={handleFontChange} value={font}>
              <SelectTrigger className={"max-w-[24ch] min-w-[16ch]"}>
                <SelectValue/>
              </SelectTrigger>
              <SelectContent >
                {availableFonts.map((font, index) => (
                  <SelectItem key={index} value={font}>
                    {font}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
      <div className={"flex items-center flex-col-reverse md:flex-row justify-center md:items-end gap-3"}>
        <div id={first ? tourElementIds.textPreview : ''}>
          <Preview text={text} font={font}/>
        </div>
        {featureFlags.isAddRowEnabled && removeRow && (
          <Button onClick={removeRow}
                  variant={"ghost"}
                  size={"icon"}
                  className="text-red-500 hover:bg-red-200 hover:text-red-500">
            <XMarkIcon className={"h-8 w-8"}/>
          </Button>
        )}
      </div>
    </div>
  );
};

export default TextRow;