import React from 'react';
import { TourProvider } from '@reactour/tour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { steps } from "@/app/tour";

interface TourWrapperProps {
  children: React.ReactNode;
}

/**
 * This is a wrapper to provide a context for the React Tour library.
 *
 * The tour is only used in the main app screen, and this wrapper is used in `PageWrapper`.
 *
 * Currently, when the tour is active, scrolling is disabled.
 */
export const TourWrapper = ({ children }: TourWrapperProps) => {
  const disableBody = (target: any) => disableBodyScroll(target);
  const enableBody = (target: any) => enableBodyScroll(target);

  return (
    <TourProvider steps={steps}
                  afterOpen={disableBody}
                  beforeClose={enableBody}
                  showBadge={false}>
      {children}
    </TourProvider>
  );
};