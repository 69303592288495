/**
 * This file the steps for the tour.
 *
 * The tour provider is used in `PageWrapper` (since that is where the header is inserted, which is needed for the trigger)
 */

export const tourElementIds = {
  svgUpload: "svgUploadElement",
  textInput: "textInputElement",
  textPreview: "textPreviewElement",
  performNesting: "performNestingElement",
  run: "runElement",
  measurementsTable: "measurementsTableElement",
  nestingResult: "nestingResultElement",
  nestingResultUsage: "nestingResultUsageElement",
  nestingResultButtons1: "nestingResultButtonsElement1",
  nestingResultButtons2: "nestingResultButtonsElement2",
  nestingResultDownload: "nestingResultDownloadElement",
  clear: "clearElement",
}


export const steps = [
  {
    selector: `#${tourElementIds.svgUpload}`,
    content: 'Use this area to upload SVG files. This file can contain your text or even abstract shapes (ie: a logo)! ' +
      'You can upload one file, and Nestrr will analyze the shapes and display useful information.',
  }, {
    selector: `#${tourElementIds.textInput}`,
    content: 'Enter your text here to be measured and nested alongside of any SVG file you upload. ' +
      'You can adjust the font size and font.',
  }, {
    selector: `#${tourElementIds.textPreview}`,
    content: 'Press this button to see how your text will look when rendered.'
  }, {
    selector: `#${tourElementIds.performNesting}`,
    content: 'Enable this option to nest your text and/or SVG file. Nesting will optimize the layout of your shapes to ' +
      'minimize material waste. You can adjust the width and height of your material as well as use the presets.'
  }, {
    selector: `#${tourElementIds.run}`,
    content: 'Press this button to run Nestrr, get your measurements, and see the optimized layout (if nesting is enabled).',
  }, {
    selector: `#${tourElementIds.measurementsTable}`,
    content: 'This table shows the measurements of your text and/or SVG file. ' +
      'You can see the length and perimeter for both the text and the SVG file (if uploaded).',
  }, {
    selector: `#${tourElementIds.nestingResult}`,
    content: 'This area is where the nested sheets will appear.'
  }, {
    selector: `#${tourElementIds.nestingResultUsage}`,
    content: 'This area shows the percentage of material used. ' +
      'For example, if "3.4", there are 3 sheets fully used, and 40% usage on a fourth sheet.',
  }, {
    selector: `#${tourElementIds.nestingResultButtons1}`,
    content: 'Use these buttons to cycle through all of the nested sheets.'
  }, {
    selector: `#${tourElementIds.nestingResultButtons2}`,
    content: 'Use these buttons to cycle through all of the nested sheets.'
  }, {
    selector: `#${tourElementIds.nestingResultDownload}`,
    content: 'Press this button to download all of the nested sheets as a zip file.'
  }, {
    selector: `#${tourElementIds.clear}`,
    content: 'Press this button to clear the text and remove the SVG file so you can start fresh.'
  }
];