import React from "react";

import {Header, StaticHeader} from "./Header";
import {Footer} from "./Footer";

import './assets/tour.css';
import {TourWrapper} from "@/components/TourWrapper";

interface PageWrapperProps {
  children: React.ReactNode;
  staticContent?: boolean;
}

/**
 * PageWrapper component
 *
 * Used for laying out the page with the header and footer and ensuring that each page uses the entire height of the screen.
 *
 * Additionally, the ShepherdTour context is used to provide a guided tour of the main app screen. This is not used for
 * any other pages.
 */
export const PageWrapper = ({children, staticContent}: PageWrapperProps) => {
  const header = staticContent ? <StaticHeader/> : <Header/>;

  return (
    <div className={"flex flex-col min-h-[100vh]"}>
      <TourWrapper>
        {header}
        <div className={'flex-grow'}>
          {children}
        </div>
        <Footer/>
      </TourWrapper>
    </div>
  )
}