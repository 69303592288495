import React from "react";
import {Card} from "@/components/ui/Card";

/**
 * A card that is centered on the page.
 *
 * This is a helper component used for layout purposes and used in the auth pages, as well as `FirstLogin.tsx`.
 * @param children
 * @constructor
 */
export const CenteredCard = ({ children }: { children: React.ReactNode }) => (
  <div className={"flex items-center justify-center mt-[10vh] mb-[10vh]"}>
    <Card className={"w-[95vw] sm:w-[65vw] md:w-[50vw] lg:w-[40vw]"}>
      {children}
    </Card>
  </div>
);
