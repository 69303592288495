import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger} from "@/components/ui/Dialog";
import {Button} from "@/components/ui/Button";
import {EyeIcon} from "@heroicons/react/24/solid";

interface PreviewProps {
  text: string;
  font: string;
}

/**
 * Render a button which opens a `Dialog` modal to view the rendered text in the specified font.
 *
 * @param text - Text to render
 * @param font - Font to render the text in
 */
export const Preview = ({ text, font }: PreviewProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"ghost"} size={"icon"} disabled={text.length === 0}>
          <EyeIcon className={"h-6 w-6 text-gray-500"}/>
        </Button>
      </DialogTrigger>
      <DialogContent className={"max-w-[90vw]"}>
        <DialogHeader>
          <DialogTitle>Rendered Text</DialogTitle>
          <DialogDescription>This is how your text looks in the {font} font.</DialogDescription>
        </DialogHeader>
        <div style={{fontFamily: font, fontSize: "3rem"}} className={"border-2 md:border-gray-200 md:p-4 lg:p-6 rounded-lg"}>{text}</div>
      </DialogContent>
    </Dialog>
  )
}