import React, {useState} from 'react';
import {Link} from "react-router-dom";
import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import { Button } from '@/components/ui/Button';
import {CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@/components/ui/Card";
import {Alert, AlertDescription, AlertTitle} from "@/components/ui/Alert";
import {SUPABASE} from "@/lib/db";
import {CheckIcon} from "@heroicons/react/24/solid";
import { CenteredCard } from '@/components/CenteredCard';

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<string | null>(null);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setMessage(null);
  }


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {error} = await SUPABASE.auth.resetPasswordForEmail(
      email,
      {
        redirectTo: `${window.location.origin}/update-password`
      }
    );
    if (!error) {
      setEmail("");
      setMessage("If you have an account with us, an email has been sent with a link to reset your password.");
    }
  }


  return (
    <CenteredCard>
      <form onSubmit={handleSubmit}>
        <CardHeader>
          <CardTitle>
            Password Reset
          </CardTitle>
          <CardDescription>
            Enter your email to send a password reset link. You can also <Link to="/login" className={"text-blue-500 underline"}>login</Link>
            &nbsp;or <Link to="/register" className={"text-blue-500 underline"}>register</Link>.
          </CardDescription>
        </CardHeader>
        <CardContent>
          {message &&
              <Alert className={"text-green-500 border-green-500/70 mb-4"}>
                  <CheckIcon className="h-4 w-4 text-green-500"/>
                  <AlertTitle>Success!</AlertTitle>
                  <AlertDescription>{message}</AlertDescription>
              </Alert>
          }
          <div>
            <Label>
             Email
            </Label>
            <Input
              type="text"
              value={email} onChange={handleEmailChange}
              required
            />
          </div>
        </CardContent>
        <CardFooter className={"flex justify-end"}>
          <Button type="submit">Reset Password</Button>
        </CardFooter>
      </form>
    </CenteredCard>
  );
};
