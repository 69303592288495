import React, { useState } from 'react';
import {Link} from "react-router-dom";

import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import { Button } from '@/components/ui/Button';
import {CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@/components/ui/Card";
import {Alert, AlertDescription, AlertTitle} from "@/components/ui/Alert";
import {CheckIcon} from "@heroicons/react/24/solid";

import {SUPABASE} from "@/lib/db";
import { CenteredCard } from '@/components/CenteredCard';
import {Checkbox} from "@/components/ui/checkbox";
import {signInWithGoogle, signInWithMicrosoft} from "@/lib/auth";
import GoogleIcon from "@/pages/auth/img/google-icon.svg";
import MicrosoftIcon from "@/pages/auth/img/microsoft-icon.svg";
import {OAuthButton} from "@/components/OAuthButton";


type RegistrationResponse = {
  status: string;
  message: string;
}

export const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState<RegistrationResponse | null>(null);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setErrorMessage("Passwords do not match");
    } else {
      setErrorMessage("");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const {data, error} = await SUPABASE.auth.signUp(
      {
        email: email,
        password: password,
        options: {
          emailRedirectTo: `${window.location.origin}/email/verified`
        }
      })
    if (error) {
      console.error(error);
      setStatusMessage({
        'status': 'Error!',
        'message': error.message
      });
      return;
    }
    if (data) {
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setStatusMessage({
        'status': 'Success!',
        'message': `An confirmation email has been sent to ${data.user?.email}. `
                    + 'Please click the link in the email to confirm your account.'}
      );
    }
  };

  return (
    <CenteredCard>
      <CardHeader>
        <CardTitle>
          Register
        </CardTitle>
        <CardDescription>
          Create your account, or <Link to="/login" className={"text-blue-500 underline"}>login</Link>.
        </CardDescription>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent>
          {statusMessage &&
              <Alert className={"text-green-500 border-green-500/70 mb-4"}>
                  <CheckIcon className="h-4 w-4 text-green-500"/>
                  <AlertTitle>{statusMessage?.status}</AlertTitle>
                  <AlertDescription>{statusMessage?.message}</AlertDescription>
              </Alert>
          }

          <div className={"flex flex-col justify-around mb-4 w-full gap-2"}>
            <OAuthButton callback={signInWithGoogle} icon={GoogleIcon} text={"Sign in with Google"}/>
            <OAuthButton callback={signInWithMicrosoft} icon={MicrosoftIcon} text={"Sign in with Microsoft"}/>
          </div>

          <div>
            <Label>
              Email
            </Label>
            <Input
              type="email"
              className={"min-w-full"}
              placeholder={"Email"}
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div>
            <Label>
              Password
            </Label>
            <Input
              type="password"
              minLength={8}
              className={"min-w-full"}
              placeholder={"Password"}
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
              required
            />
          </div>
          <div>
            <Label>
              Confirm Password
            </Label>
            <Input
              type="password"
              minLength={8}
              className={`min-w-full ${errorMessage ? "border-red-500" : ""}`}
              placeholder={"Confirm Password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              autoComplete="new-password"
              required
            />
            {errorMessage && <p className="text-red-500 text-sm mt-1 font-light">{errorMessage}</p>}
          </div>

          <div className={"mt-4 flex gap-2 items-center"}>
            <Checkbox id="terms" required/>
            <Label className={"text-sm font-light"}>
              By registering, you agree to our <Link to="/terms" className={"text-blue-500 underline"}>Terms of
              Service</Link> and <Link to="/privacy" className={"text-blue-500 underline"}>Privacy Policy</Link>.
            </Label>
          </div>
        </CardContent>
        <CardFooter className={"flex justify-end"}>
          <Button type="submit">Register</Button>
        </CardFooter>
      </form>
    </CenteredCard>
  );
};