import { Button } from "@/components/ui/Button";
import {Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger} from "@/components/ui/Dialog";
import {TrashIcon, EyeIcon} from "@heroicons/react/24/solid";
import React from "react";

interface UploadedSvgProps {
  filename: string;
  image: string;
  onRemove: () => void;
}


const SvgPreview = ({image}: {image: string}) =>
  <Dialog>
    <DialogTrigger asChild>
      <Button variant={"secondary"}>
        <EyeIcon className={"h-4 w-4 mr-1"}/>
        Preview
      </Button>
    </DialogTrigger>
    <DialogContent className="sm:max-w-[425px] bg-white">
      <DialogHeader>
        <DialogTitle>View SVG</DialogTitle>
      </DialogHeader>
      <div>
        <img src={image} alt="Uploaded SVG" className={"max-h-[75vh]"}/>
      </div>
    </DialogContent>
  </Dialog>

const SvgItem = ({filename, image, onRemove}: UploadedSvgProps) => {
  return (
    <div className={"py-2 px-4 w-fit mt-2 flex flex-row gap-4 items-center"}>
      {filename}
      <SvgPreview image={image}/>
      <Button onClick={onRemove}
              variant={"ghost"}
              size={"icon"}
              className="text-red-500 hover:bg-red-200 hover:text-red-500">
        <TrashIcon className={"h-4 w-4"}/>
      </Button>
    </div>
  )
}

export default SvgItem;