import {Button} from "@/components/ui/Button";
import React from "react";

export const OAuthButton = ({callback, icon, text}: { callback: () => void, icon: string, text: string }) => {
  return (
    <Button variant={'outline'} onClick={callback}>
      <img src={icon} alt="provider icon" className={"h-4 w-4 mr-2"}/>
      <span className={"text-gray-600 font-light"}>{text}</span>
    </Button>
  )
}