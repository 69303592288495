import React, {useEffect} from "react";
import {Logout, RequireToken} from "@/lib/auth";
import {createBrowserRouter, redirect, useNavigate} from "react-router-dom";
import {PageWrapper} from "@/components/PageWrapper";
import {Home} from "@/pages/static/Home";
import {CenteredSpinner} from "@/components/Spinner";
import {Login} from "@/pages/auth/Login";
import {ForgotPassword} from "@/pages/auth/ForgotPassword";
import {UpdatePassword} from "@/pages/auth/UpdatePassword";
import {Register} from "@/pages/auth/Register";
import {Account} from "@/pages/Account";
import {FirstLogin} from "@/pages/FirstLogin";
import {getCustomerPortal, getPaymentRedirect} from "@/api/services/payments";
import {NotFound} from "@/pages/NotFound";
import {useSession} from "@/hooks/useSession";

/**
 * Lazy load the main app component and static pages.
 *
 * This is to reduce the initial bundle size of the application.
 */

const App = React.lazy(() => import('./app').then(module => ({ default: module.App })));
const Privacy = React.lazy(() => import('@/pages/static/Privacy').then(module => ({ default: module.Privacy })));
const TermsAndConditions = React.lazy(() => import('@/pages/static/TermsAndConditions').then(module => ({ default: module.TermsAndConditions })));
const EmailVerified = React.lazy(() => import('@/pages/auth/EmailVerified').then(module => ({ default: module.EmailVerified })));

/**
 * Component used to redirect the root path to either the home page or the main app page.
 *
 * If the user is logged in, a redirect to the app page is performed. Otherwise, a redirect to the home page is performed.
 */
const RootRedirect = () => {
  const {loading, session} = useSession();

  const navigate = useNavigate();

  const isLoggedIn = !!session;

  useEffect(() => {
    if (loading) {
      return;
    } else if (isLoggedIn) {
      navigate("/app");
    } else {
      navigate("/home");
    }
  }, [navigate, isLoggedIn, loading]);

  return <></>;
}

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <RootRedirect />
  }, {
    path: "/home",
    element: <PageWrapper><Home /></PageWrapper>
  }, {
    path: "/app",
    element:
      <PageWrapper>
        <RequireToken>
          <React.Suspense fallback={<CenteredSpinner />}>
            <App />
          </React.Suspense>
        </RequireToken>
      </PageWrapper>
  }, {
    path: "/privacy",
    element:
      <PageWrapper>
        <React.Suspense fallback={<CenteredSpinner />}>
          <Privacy />
        </React.Suspense>
      </PageWrapper>
  }, {
    path: "/login",
    element:
      <PageWrapper staticContent={true}>
        <Login/>
      </PageWrapper>
  }, {
    path: "/email/verified",
    element:
      <PageWrapper staticContent={true}>
        <React.Suspense fallback={<CenteredSpinner />}>
          <EmailVerified />
        </React.Suspense>
      </PageWrapper>
  }, {
    path: "/forgot-password",
    element:
      <PageWrapper staticContent={true}>
        <ForgotPassword />
      </PageWrapper>
  }, {
    path: "/update-password",
    element: <UpdatePassword />
  }, {
    path: "/register",
    element: <PageWrapper><Register /></PageWrapper>
  }, {
    path: "/logout",
    element:
      <PageWrapper>
        <RequireToken><Logout /></RequireToken>
      </PageWrapper>
  }, {
    path: "/account",
    element:
      <PageWrapper>
        <RequireToken><Account /></RequireToken>
      </PageWrapper>
  }, {
    path: "login/first",
    element:
      <FirstLogin />
  }, {
    path: "/subscribe",
    loader: async () => {
      const url = await getPaymentRedirect();
      return redirect(url)
    }
  }, {
    path: "/customer_portal",
    loader: async () => {
      const url = await getCustomerPortal();
      return redirect(url)
    }
  }, {
    path: "/terms",
    element:
      <PageWrapper>
        <React.Suspense fallback={<CenteredSpinner />}>
          <TermsAndConditions />
        </React.Suspense>
      </PageWrapper>
  }, {
    path: "*",
    element: <PageWrapper><NotFound /></PageWrapper>
  }
]);

