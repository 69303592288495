import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "@/components/ui/Card";
import { Button } from "@/components/ui/Button";

import { SvgInput } from "@/app/InputContainer/SvgInput";
import TextRow from "@/app/InputContainer/TextInput/TextRow";
import {Row} from "@/types/Row";
import {UploadedSvg} from "@/types/UploadedSvg";


export const Home = () => {
  const [uploadedSvg, setUploadedSvg] = useState<UploadedSvg | null>(null);
  const [font, setFont] = React.useState<string>("Arial");
  const [fontSize, setFontSize] = React.useState<number>(12);
  const [text, setText] = React.useState<string>("");

  const navigate = useNavigate();

  /**
   * Store the data inputted by the user and navigate to the registration page.
   *
   * Data is stored in a local session object so that it can be retrieved in the main app page. After the data is retrieved,
   * it is immediately cleared from the session object. See `src/app/InputContainer/hooks/useInputContainer.ts` for more details.
   */
  const initiateRegistration = () => {
    // store request text
    const initialRequest: Row = {font, size: fontSize, text};
    window.sessionStorage.setItem("initialRequest", JSON.stringify(initialRequest));

    // store SVG content
    window.sessionStorage.setItem("uploadedSvg", JSON.stringify(uploadedSvg));

    navigate("/register");
  }

  return (
    <div className={"p-8 sm:px-16 md:px-40 lg:px-60 lg:py-8"}>

      <section className={"flex flex-col gap-8"}>
        <aside>
          <h1 className={"text-2xl"}>Measurement & Nesting</h1>
          <p>
            Nestrr can help you estimate how much material you need for your project. Get measurements and nesting outputs based
            on text or SVG files.
          </p>
        </aside>

        <Card>
          <CardHeader>
            <CardTitle>
              Text Input
            </CardTitle>
          </CardHeader>
          <CardContent>
            <SvgInput setSvgContent={setUploadedSvg} svgContent={uploadedSvg}/>
            <TextRow font={font} text={text} onTextChange={setText} onFontChange={setFont} size={fontSize} onSizeChange={setFontSize} />
          </CardContent>
          <CardFooter>
            <div className={"w-full flex flex-row-reverse"}>
              <Button onClick={initiateRegistration}>
                Start Now!
              </Button>
            </div>
          </CardFooter>
        </Card>
      </section>

    </div>
  )
}