/** Payment functions **/
import {endpoints} from "@/api/endpoints";
import {authenticatedRequestBody} from "@/api/services/auth";


export const getCustomerPortal = async (): Promise<string> => {
  const session_data = await authenticatedRequestBody(null);

  const response = await fetch(endpoints.customer_portal, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: session_data,
  });

  const data = await response.json();
  return data.url;
}


/**
 * Post an authenticated request to the checkout endpoint to get the checkout URL to redirect to.
 *
 * This is used to get the checkout URL to redirect to for payment.
 */
export const getPaymentRedirect = async (): Promise<string> => {
  const session_data = await authenticatedRequestBody(null);

  const response = await fetch(endpoints.subscribe, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: session_data,
  });

  const data = await response.json();
  return data.url
}