import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {Input} from '@/components/ui/Input';
import {Label} from '@/components/ui/Label';
import {Button} from '@/components/ui/Button';
import {CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@/components/ui/Card";
import {Alert, AlertDescription, AlertTitle} from "@/components/ui/Alert";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import {SUPABASE} from "@/lib/db";
import GoogleIcon from "./img/google-icon.svg";
import MicrosoftIcon from "./img/microsoft-icon.svg";
import {CenteredCard} from "@/components/CenteredCard";
import {signInWithGoogle, signInWithMicrosoft} from "@/lib/auth";
import {OAuthButton} from "@/components/OAuthButton";

type LoginResponse = {
  error: string;
}

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<LoginResponse | null>(null);

  const navigate = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {data, error} = await SUPABASE.auth.signInWithPassword({email: email, password: password})

    // sign out of other sessions
    await SUPABASE.auth.signOut({scope: 'others'})

    if (error) {
      setError({error: error.message})
      return
    }
    if (data) {
      navigate('/app')
    }
  }

  return (
    <CenteredCard>
      <CardHeader>
        <CardTitle>
          Login
        </CardTitle>
        <CardDescription>
          Please login, or <Link to="/register" className={"text-blue-500 underline"}>register</Link>.
        </CardDescription>
      </CardHeader>
      <CardContent>
        {error &&
            <Alert variant={"destructive"} className={"mb-4"}>
                <ExclamationTriangleIcon className="h-4 w-4 text-red-500"/>
                <AlertTitle>Uh Oh!</AlertTitle>
                <AlertDescription>{error?.error}</AlertDescription>
            </Alert>
        }

        <div className={"flex flex-col justify-around mb-4 w-full gap-2"}>
          <OAuthButton callback={signInWithGoogle} icon={GoogleIcon} text={"Sign in with Google"}/>
          <OAuthButton callback={signInWithMicrosoft} icon={MicrosoftIcon} text={"Sign in with Microsoft"}/>
        </div>

        <form onSubmit={handleSubmit} id={"login_form"}>
          <div>
            <Label>
              Email
            </Label>
            <Input
              type="text"
              value={email} onChange={handleEmailChange}
              required
            />
          </div>
          <div>
            <Label>
              Password
            </Label>
            <Input
              type="password"
              autoComplete="current-password"
              value={password} onChange={handlePasswordChange}
              required
            />
          </div>
        </form>
      </CardContent>
      <CardFooter className={"flex justify-end"}>
        <Link to="/forgot-password" className={"text-blue-500 underline mr-4"}>Forgot Password?</Link>
        <Button type="submit" form={"login_form"}>Login</Button>
      </CardFooter>
    </CenteredCard>
  );
};