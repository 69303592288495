import React from 'react';
import { useDropzone } from 'react-dropzone';

interface SvgUploadProps {
  onUpload: (file: File) => void;
}

const SvgUpload = ({ onUpload }: SvgUploadProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {"image/svg": [".svg"]},
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        onUpload(acceptedFiles[0]);
      }
    },
  });

  return (
    <div className={"hover:bg-gray-100 w-fit"}>
      <div {...getRootProps()} className={"flex flex-col p-4"}>
        <input {...getInputProps()} />
        <p>Drop an SVG file here, or click to select a file</p>
      </div>
    </div>
  );
};

export default SvgUpload;