import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import {useRemainingCredits} from "./hooks/useRemainingCredits";
import {QuestionMarkCircleIcon} from "@heroicons/react/24/outline";


// styling constants for specific states
const unlimitedCreditsStyling = "text-blue-400 bg-blue-100 border-blue-200";
const noCreditsStyling = "text-red-400 bg-red-100 border-red-200";
const positiveCreditsStyling = "text-green-400 bg-green-100 border-green-200";

// common styling for the component
const componentStyling = "flex text-xs items-center border-2 py-1.5 px-1.5 rounded-md";

/**
 * Component to display the remaining credits for the current user
 *
 * If the user has unlimited credits, indicate that the user has unlimited credits;
 * If the user has no remaining credits, indicate that the user has no remaining credits and should upgrade their account;
 * Otherwise, indicate the number of remaining credits.
 */
export const RemainingCredits = () => {
  const {remainingCredits, refreshDate} = useRemainingCredits();

  if (remainingCredits === undefined) {
    return null;
  }

  const componentText = remainingCredits === Infinity ? "Unlimited nesting credits" :
    remainingCredits <= 0 ? "No remaining nesting credits! Upgrade your account." :
      `Remaining nesting credits: ${remainingCredits}`;
  const styling = remainingCredits === Infinity ? unlimitedCreditsStyling :
    remainingCredits <= 0 ? noCreditsStyling : positiveCreditsStyling;

  return (
    <p className={`${styling} ${componentStyling}`}>
      {componentText}
      {refreshDate && <RefreshPopover refreshDate={refreshDate} />}
    </p>
  )
}


/**
 * This is a popover which indicates to the user when their credits will reset. This is only shown when the user is not on a paid plan.
 */
const RefreshPopover = ({refreshDate}: {refreshDate: string}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <QuestionMarkCircleIcon className="h-4 w-4 ml-1" />
        </TooltipTrigger>
        <TooltipContent>
          <span>Credits will refresh on {refreshDate}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}