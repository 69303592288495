import React from "react";
import SvgUpload from "./SvgUpload";
import SvgItem from "./SvgItem";
import {SectionHeader} from "@/app/InputContainer/SectionHeader";
import {tourElementIds} from "@/app/tour";
import {UploadedSvg} from "@/types/UploadedSvg";

interface SvgInputWrapperProps {
  svgContent: UploadedSvg | null;
  setSvgContent: (svg: UploadedSvg | null) => void;
}

export const SvgInput = ({svgContent, setSvgContent}: SvgInputWrapperProps) => {
  const acceptSvg = async (file: File) => {
    let base64data: string | ArrayBuffer | null = null;

    // convert to base64
    const reader = new FileReader();
    reader.readAsDataURL(file);

    await new Promise((resolve, reject) => {
      reader.onloadend = () => {
        base64data = reader.result;
        resolve(null);
      };
      reader.onerror = (error) => {
        console.error('Error: ', error);
        reject(error);
      };
    });

    setSvgContent({content: base64data, filename: file.name});
  }

  const svg_content = svgContent?.content as string;
  const filename = svgContent?.filename as string;

  return (
    <div id={tourElementIds.svgUpload}>
      <SectionHeader className="mb-2">SVG Upload</SectionHeader>
      {svgContent && <p className={"text-sm"}>Click the file below to preview your SVG image.</p>}
      {svgContent ? <SvgItem image={svg_content} onRemove={() => setSvgContent(null)} filename={filename}/>
      : <SvgUpload onUpload={(file: File) => acceptSvg(file)}/> }
    </div>
  )
}