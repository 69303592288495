/*************************************
 *  Constants for Input Validation   *
 *************************************/

// maximum character count/length for text input
export const MAX_TEXT_LENGTH = 40;

// minimum height for text input
export const MIN_TEXT_HEIGHT = 0.5;

// maximum height for text input
export const MAX_TEXT_HEIGHT = 120;

// minimum dimension for sheet input
export const MIN_SHEET_DIMENSION = 5;

// maximum dimension for sheet input
export const MAX_SHEET_DIMENSION = 240;


/*****************
 * Contact Email *
 *****************/
export const SUPPORT_CONTACT_EMAIL = "support@nestrr.net";
export const SUPPORT_MAILTO = `mailto:${SUPPORT_CONTACT_EMAIL}`;