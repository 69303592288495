import {useEffect, useState} from "react";
import {getRemainingCredits} from "@/api/services/user";
import {RemainingCreditsResponse} from "@/types/RemainingCreditsResponse";

/**
 * Hook to get the remaining credits for the current user
 *
 * The remaining credits is `undefined` while the request is being made, and `Infinity` if the user has unlimited credits.
 */
export const useRemainingCredits = () => {
  const [data, setData] = useState<RemainingCreditsResponse | undefined>(undefined);

  useEffect(() => {
    getRemainingCredits().then(setData);
  }, []);

  return {remainingCredits: data?.remaining_credits, refreshDate: data?.refresh_date};
}