// flag to indicate that the code is being run locally
const isDevelopment = process.env.NODE_ENV === 'development';

const devEndpoint = 'http://localhost:8000';
const prodEndpoint = "https://nestrr-backend-ym44c.ondigitalocean.app";

const endpoint = isDevelopment ? devEndpoint : prodEndpoint;

const apiEndpoint = endpoint + '/api';
const userEndpoint = endpoint + '/user';
const paymentEndpoint = endpoint + '/payments';

export const endpoints = {
  // API endpoints
  measurements: `${apiEndpoint}/measurements`,
  nest: `${apiEndpoint}/nest`,

  // User endpoints
  update_metadata: `${userEndpoint}/update/metadata`,
  user_info: `${userEndpoint}/info`,
  remainingCredits: `${userEndpoint}/remaining_credits`,

  // payment endpoints
  subscribe: `${paymentEndpoint}/subscribe`,
  customer_portal: `${paymentEndpoint}/customer_portal`,
}