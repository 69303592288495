import {authenticatedRequestBody} from "@/api/services/auth";
import {endpoints} from "@/api/endpoints";
import {RemainingCreditsResponse} from "@/types/RemainingCreditsResponse";

/**
 * Get the remaining credits for the current user.
 *
 * This requires that the user is authenticated.
 *
 * If the user has unlimited credits, return `Infinity`, otherwise return the number of remaining credits.
 * If the returned data is invalid, throw an error.
 */
export const getRemainingCredits = async (): Promise<RemainingCreditsResponse | undefined> => {
  const session_data = await authenticatedRequestBody(undefined);
  const response = await fetch(endpoints.remainingCredits, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: session_data,
  });

  if (response.ok) {
    // if response is ok, return the remaining credits
    const data = await response.json();
    if (data.remaining_credits === 'unlimited') {
      return {remaining_credits: Infinity};
    } else if (typeof data.remaining_credits !== 'number') {
      throw new Error('Invalid remaining credits returned');
    }
    return data;
  }
}

export const getUserData = async () => {
  const session_data = await authenticatedRequestBody(null);
  const data = fetch(endpoints.user_info, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: session_data,
  }).then(async (response) => {
    if (response.status !== 200) {
      console.error(response);
      return;
    }
    return await response.json();
  }).then((data) => {
    if (data.error) {
      console.error(data.error);
      return;
    } else {
      return {
        name: data.name,
        email: data.email,
        company: data.company,
        industry: data.industry,
        position: data.position,
        paid_status: data.paid_status,
      };
    }
  });
  return data
}