import React from 'react';
import './assets/spinner.css'; // If you created a separate CSS module

export const Spinner = () => {
  return (
    <div className="spinner"></div>
  );
};

export const CenteredSpinner = () => {
  return (
    <div className="min-h-[80vh] flex items-center justify-center">
      <Spinner />
    </div>
  );
}