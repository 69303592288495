import {SUPPORT_CONTACT_EMAIL, SUPPORT_MAILTO} from "@/api/constants";

export const NotFound = () => {
  return (
    <div className={"flex flex-col gap-2 items-center justify-center min-h-[90vh]"}>
      <h1 className={"text-4xl font-bold"}>404</h1>
      <h2 className={"text-2xl"}>Page Not Found</h2>
      <p className={"text-lg"}>The page you are looking for does not exist.</p>
      <p className={"text-lg"}>Please send an email to <a href={SUPPORT_MAILTO}>{SUPPORT_CONTACT_EMAIL}</a> to let us know how you got this error.</p>
    </div>
  )
}