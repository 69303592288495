import {SUPABASE} from "@/lib/db";

/**
 * Wrap data alongside of the session token to make a request to an authenticated endpoint
 *
 * @param request_data - the data to be sent in the request
 */
export const authenticatedRequestBody = async (request_data: any) => {
  const {data, error} = await SUPABASE.auth.getSession();
  if (error) {
    throw new Error("Error: failed to get session");
  }
  if (!data) {
    throw new Error("Error: no session data");
  }

  const token = data.session?.access_token;

  if (!token) {
    throw new Error("Error: no token in session data");
  }

  if (!request_data) {
    return JSON.stringify({
      token: token
    })
  }
  return JSON.stringify({
    data: request_data,
    session: {
      token: token
    }
  })
}