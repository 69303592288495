import React, {ReactNode, useContext} from 'react';
import {
  ArrowLeftEndOnRectangleIcon,
  ArrowLeftStartOnRectangleIcon,
  Bars3Icon,
  HomeIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon
} from "@heroicons/react/24/outline";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/Sheet"
import {Link, useLocation} from "react-router-dom";
import { useTour } from '@reactour/tour'
import {useSession} from "@/hooks/useSession";


const HomeElement = () => {
  return (
    <Link to="/home">
      <div className={"flex flex-row items-center gap-2"}>
        <HomeIcon className={"h-4 w-4"} />
        <span>Home</span>
      </div>
    </Link>
  )
}

const AppElement = () => {
  return (
    <Link to="/app">
      <div className={"flex flex-row items-center gap-2"}>
        <HomeIcon className={"h-4 w-4"} />
        App
      </div>
    </Link>
  )
}

const AccountElement = () => {
  return (
    <Link to="/account">
      <div className={"flex flex-row items-center gap-2"}>
        <UserCircleIcon className={"h-4 w-4"} />
        <span>Account</span>
      </div>
    </Link>
  )
}

const LogOutElement = () => {
  return (
    <Link to="/logout">
      <div className={"flex flex-row items-center gap-2"}>
        <ArrowLeftStartOnRectangleIcon className={"h-4 w-4"} />
        <span>Log Out</span>
      </div>
    </Link>
  )
}

const LogInElement = () => {
  return (
    <Link to="/login">
      <div className={"flex flex-row items-center gap-2"}>
        <ArrowLeftEndOnRectangleIcon className={"h-4 w-4"} />
        <span>Log In</span>
      </div>
    </Link>
  )

}

const HelpElement = () => {
  const { setIsOpen } = useTour();

  return (
    <div className={"flex flex-row items-center gap-2 cursor-pointer"} onClick={() => setIsOpen(true)}>
      <QuestionMarkCircleIcon className={"h-4 w-4"} />
      <span>Help</span>
    </div>
  )
}

interface HeaderContainerProps {
  links: ReactNode[];
}

/**
 * Container for the header. Wraps functionality for rendering the links as a sidebar on mobile.
 * @param links - `ReactNode` elements to render as links or buttons in the header.
 */
const HeaderContainer = ({links}: HeaderContainerProps) => {
  // state for the sidebar. Used for mobile only.
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <header className="flex items-center p-4 bg-primary text-white shadow">
      <div className={"flex-grow"}>
        <div className={"w-fit"}>
          <Link to={"/home"}>
            <h1 className={"flex flex-row items-center gap-2"}>
              <img src={"/icon_small.png"} alt={"Nestrr Logo"} className={"h-8 w-8 -mt-1 -mb-1"} />
              Nestrr
            </h1>
          </Link>
        </div>
      </div>
      <nav className="gap-4 hidden md:flex">
        <ul className={"flex flex-row gap-4"}>
          {links.map((link, index) => (
            <li key={index}>
              {link}
            </li>
          ))}
        </ul>
      </nav>
      <Sheet open={sidebarOpen} onOpenChange={() => setSidebarOpen(!sidebarOpen)}>
        <SheetTrigger onClick={() => setSidebarOpen(true)}>
          <Bars3Icon className={"h-6 w-6 md:hidden"} />
        </SheetTrigger>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Navigation</SheetTitle>
          </SheetHeader>
          <nav className={"mt-6"}>
            <ul className={"flex flex-col gap-4"}>
              {links.map((link, index) => (
                <li key={index} onClick={() => setSidebarOpen(false)}>
                  {link}
                </li>
              ))}
            </ul>
          </nav>
        </SheetContent>
      </Sheet>
    </header>
  );
}


/**
 * Dynamic header which changes it's values depending on where the user is, and if the user is logged in.
 * @constructor
 */
export const Header = () => {
  const {session} = useSession();

  const location = useLocation();

  const onAppScreen = location.pathname === "/app";
  const isLoggedIn = !!session;

  const linkElements = {
    home: {element: <HomeElement />, condition: !isLoggedIn},
    app: {element: <AppElement />, condition: !onAppScreen && isLoggedIn},
    account: {element: <AccountElement />, condition: isLoggedIn},
    logout: {element: <LogOutElement />, condition: isLoggedIn},
    login: {element: <LogInElement />, condition: !isLoggedIn && !onAppScreen},
    help: {element: <HelpElement />, condition: onAppScreen}
  };

  const links = Object.values(linkElements)
    .filter(({condition}) => condition)
    .map(({element}) => element);

  return <HeaderContainer links={links} />;
};


/**
 * Static header which only contains the Home and Log In links.
 *
 * The logo is also a link to the home page.
 */
export const StaticHeader = () => {
  const links = [<HomeElement />, <LogInElement />]

  return <HeaderContainer links={links} />
}