import React, {useEffect, useState} from 'react';

import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import { Button } from '@/components/ui/Button';
import {CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@/components/ui/Card";
import {Alert, AlertDescription, AlertTitle} from "@/components/ui/Alert";
import {CheckIcon} from "@heroicons/react/24/solid";

import {SUPABASE} from "@/lib/db";
import {useNavigate} from "react-router-dom";
import {CenteredCard} from "@/components/CenteredCard";


type RegistrationResponse = {
  status: string;
  message: string;
}

export const UpdatePassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState<RegistrationResponse | null>(null);

  const navigate = useNavigate();

  /** Get the email of the current user
   *
   * This is used in the form to populate a hidden input to give proper context for password managers.
   */
  useEffect(() => {
    SUPABASE.auth.getUser().then(({data}) => {
      setEmail(data?.user?.email || "");
    });
  }, []);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setErrorMessage("Passwords do not match");
    } else {
      setErrorMessage("");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const {error} = await SUPABASE.auth.updateUser(
      {
        password: password,
    })
    if (error) {
      console.error(error);
      setStatusMessage({
        'status': 'Error!',
        'message': error.message
      });
    }
    navigate('/');
  };

  return (
    <CenteredCard>
      <CardHeader>
        <CardTitle>
          New Password
        </CardTitle>
        <CardDescription>
          Please enter your new password.
        </CardDescription>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent>
          {statusMessage &&
              <Alert className={"text-green-500 border-green-500/70 mb-4"}>
                  <CheckIcon className="h-4 w-4 text-green-500"/>
                  <AlertTitle>{statusMessage?.status}</AlertTitle>
                  <AlertDescription>{statusMessage?.message}</AlertDescription>
              </Alert>
          }

          {/* These inputs are hidden and disabled, but are used to give context to password managers to the current account */}
          <input type={"text"} value={email} name={"email"} autoComplete={"email"} hidden disabled/>
          <input type={"text"} value={email} name={"username"} autoComplete={"username"} hidden disabled/>

          <div>
            <Label>
              Password
            </Label>
            <Input
              type="password"
              minLength={8}
              className={"min-w-full"}
              placeholder={"New Password"}
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
              required
            />
          </div>
          <div>
            <Label>
              Confirm Password
            </Label>
            <Input
              type="password"
              minLength={8}
              placeholder={"Confirm New Password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className={`min-w-full ${errorMessage ? "border-red-500" : ""}`}
              autoComplete="new-password"
              required
            />
            {errorMessage && <p className="text-red-500 text-sm mt-1 font-light">{errorMessage}</p>}
          </div>
        </CardContent>
        <CardFooter className={"flex justify-end"}>
          <Button type="submit">Update Password</Button>
        </CardFooter>
      </form>
    </CenteredCard>
  );
};
