import {SUPPORT_MAILTO} from "@/api/constants";

export const Footer = () => {
  return (
    <footer className="bg-primary/70 text-white p-8 flex flex-row justify-center font-light items-end">
      <ul className={"flex flex-col gap-1 justify-stretch flex-grow"}>
        <li>
          <a href="/privacy">Privacy Policy</a>
        </li>
        <li>
          <a href="/terms">Terms of Service</a>
        </li>
        <li>
          <a href={SUPPORT_MAILTO}>Contact Us</a>
        </li>
      </ul>
      <p>© 2024 1D Software</p>
    </footer>
  );
}