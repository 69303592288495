import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";

import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import { Button } from '@/components/ui/Button';
import {CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@/components/ui/Card";

import {endpoints} from "@/api/endpoints";
import {SUPABASE} from "@/lib/db";
import { CenteredCard } from '@/components/CenteredCard';
import {authenticatedRequestBody} from "@/api/services/auth";


export const FirstLogin = () => {
  const navigate = useNavigate();

  const [user_name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [industry, setIndustry] = useState("");

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => setCompany(e.target.value);
  const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => setPosition(e.target.value);
  const handleIndustryChange = (e: React.ChangeEvent<HTMLInputElement>) => setIndustry(e.target.value);


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await SUPABASE.auth.refreshSession();
    const session_data = await authenticatedRequestBody({
      name: user_name,
      company: company,
      position: position,
      industry: industry,
    })

    await fetch(endpoints.update_metadata, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: session_data,
    })

    navigate('/app');
  };

  return (
    <CenteredCard>
      <CardHeader>
        <CardTitle>
          Welcome
        </CardTitle>
        <CardDescription>
          Please fill out some basic information before continuing.
        </CardDescription>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <div>
            <Label>
              Name
            </Label>
            <Input
              type="text"
              className={"min-w-full"}
              placeholder={"First Last"}
              value={user_name}
              onChange={handleNameChange}
              required
            />
          </div>
          <div>
            <Label>
              Industry
            </Label>
            <Input
              type="text"
              className={"min-w-full"}
              placeholder={"Your Industry (ie: Signage, Construction, etc)"}
              value={industry}
              onChange={handleIndustryChange}
              required
            />
          </div>
          <div>
            <Label>
              Company
            </Label>
            <Input
              type="text"
              className={"min-w-full"}
              placeholder={"Your Company Name"}
              value={company}
              onChange={handleCompanyChange}
            />
          </div>
          <div>
            <Label>
              Position / Role
            </Label>
            <Input
              type="text"
              className={"min-w-full"}
              placeholder={"Your position / role in your company"}
              value={position}
              onChange={handlePositionChange}
            />
          </div>


        </CardContent>
        <CardFooter className={"flex justify-end"}>
          <Button type="submit">Continue</Button>
        </CardFooter>
      </form>
    </CenteredCard>
  );
};
