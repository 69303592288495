import React from 'react';
import ReactDOM from 'react-dom/client';

import './globals.css'
import {RouterProvider, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {Toaster} from "@/components/ui/toaster";
import {routes} from "@/routes";


Sentry.init({
  dsn: "https://270333f911530317d30eb1c9ea516a78@o4505875278528512.ingest.us.sentry.io/4507153867210752",
  integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
    }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/nestrr\.app/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const Wrapper = () => {
  return (
    <React.StrictMode>
      <main>
        <RouterProvider router={routes}/>
      </main>
      <Toaster/>
    </React.StrictMode>
  )
}


root.render(
  <Wrapper />
);
