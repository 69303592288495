import {cn} from "@/lib/utils";
import React from "react";

export const SectionHeader = ({children, className, ...props}: React.HTMLAttributes<HTMLHeadingElement>) => (
  <h2
    className={cn("text-sm font-semibold text-gray-500", className)}
    {...props}
  >
    {children}
  </h2>
)